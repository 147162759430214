"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'Kiramune Digital Collection',
    organizationId: 'y43HTnZNX6sA1C2Lsutv',
    version: '1.0',
    ionicAppId: 'f8f2ad58',
    applicationKey: 'kiramune',
    applicationType: 'organization',
    artistId: undefined,
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.kiramune',
        appId: '6497652875',
    },
    android: {
        bundleId: 'com.utoniq.kiramune',
    },
    platform: ['web', 'native'],
    custom: {
        modules: {
            tutorial: false,
            trade: true,
            membership: false,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: false,
            liveStream: true,
            item: true,
            store: true,
            campaign: true,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
        ],
        globalTabs: ['home', 'myCollections', 'campaign-events'],
        artistTabs: ['home', 'collections', 'gachas', 'trade', 'threads'],
    },
    deeplinkUrl: 'https://kiramune.utoniq.com',
    applink: 'kiramune.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3562292',
    storagePath: 'kiramune',
    dynamicLinkPrefix: 'kiramune',
};
exports.default = appConfig;
